import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import * as serviceWorker from './serviceWorker';
import history from './utils/history.js';

import App from './App';
import { CmsDataProvider } from 'context/cmsData';
import { ImageViewerProvider } from 'context/imageViewer';
import AmpEmailAPI from 'api/AmpEmailAPI';

import './styles/tailwind_output.css';
import './index.css';
import './every-layout.css';

const trackingId = "G-EP3W49Q3TH"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const pixelId = "1449826802422893"; //  for lakehouseinnlee Inn
ReactPixel.init(pixelId, {}, pixelOptions);

// ReactGA.set({ page: "/index" }); // Update the user's current page
// ReactGA.pageview("/index"); // Record a pageview for the given page 
console.log(window.location.pathname);
ReactGA.send('pageview');
ReactPixel.trackSingle(pixelId, "PageView");

history.listen(location => {
  AmpEmailAPI.trackPage(location.pathname.replace("/", ""));
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.send('pageview');
  ReactPixel.trackSingle(pixelId, "PageView");
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        <ImageViewerProvider>
          <CmsDataProvider>
            <App />
          </CmsDataProvider>
        </ImageViewerProvider>
      </GoogleReCaptchaProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
