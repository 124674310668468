import React, { useEffect,useState } from 'react';
import { IoIosArrowUp, IoMdArrowUp } from 'react-icons/io';



const ScrollToTopButton =({topRef}) =>{
    const [isOnTop, setIsOnTop] = useState(true);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);

    const trackScroll = (e) => {
        if(window.scrollY > window.outerHeight){
            setIsScrolledToTop(false);
            setIsOnTop(false);
        }else{
            setIsScrolledToTop(true);
            setIsOnTop(true);
        }
    };
    window.document.addEventListener("scroll", trackScroll, true)

    useEffect(() => {
        return () => {
            window.document.removeEventListener("scroll", trackScroll)
        }
    }, []);


    return <button
    className={`fixed hide-on-lg bg-[#CCDAE1] z-10 text-black rounded-full p-1 bottom-[8px] right-[36px] translate-x-1/2 transition-transform duration-300	${isScrolledToTop ? 'scale-0' : ''}`}
    onClick={() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }}
    >
 <IoIosArrowUp className={`icon-sm animate-bounce w-12 h-12`}></IoIosArrowUp>
    </button>
    
}

export default ScrollToTopButton;