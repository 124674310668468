import React, { useState, createContext, useContext } from "react";
import Loader from 'component/Loader';

export const CmsDataContext = createContext();


function CmsDataProvider(props) {
  const [cmsData, setCmsData] = useState({});

  const getRequiredCmsData = async (types) => {
    const typesWithRequiredTypes = [...types, 'core-data'];
    const unfetchedTypes = typesWithRequiredTypes.filter(type => cmsData[type] === undefined);
    if (unfetchedTypes.length === 0) return;
    const query = unfetchedTypes.map(type => `types=${type}`).join('&');

    fetch(`${process.env.REACT_APP_API_DOMAIN}/all?${query}`)
      .then(response => response.json())
      .then(newData => {
        const updatedData = { ...cmsData }

        for (const key in newData) {
          updatedData[key] = newData[key];
        }
        setCmsData(updatedData);
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      });
  }

  const value = { cmsData, getRequiredCmsData };

  return <CmsDataContext.Provider value={value} {...props} />;
}

function useCmsDataContext() {
  return useContext(CmsDataContext);
}


/**
 * CmsDataWrapper which wraps top level page components and delegates 
 */
const CmsDataWrapper = (Component, requiredTypes) => function Comp(props) {
  const { cmsData, getRequiredCmsData } = useCmsDataContext();
  getRequiredCmsData(requiredTypes);

  let hasAllRequiredData = true;

  for (const type of requiredTypes) {
    hasAllRequiredData = hasAllRequiredData && !!cmsData[type];
    if (!hasAllRequiredData) break;
  }

  if (!hasAllRequiredData) {
    return <Loader></Loader>
  } else {
    return <Component cmsData={cmsData} {...props}></Component>
  }
}

export { CmsDataProvider, useCmsDataContext, CmsDataWrapper };
