import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Navbar from './container/NavBar';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';
import { ImageViewer } from 'context/imageViewer';
import ScrollToTopButton from 'component/ScrollToTopButton';

const HomePage = React.lazy(() => import('./container/Home'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const RoomCategoryPage = React.lazy(() => import('./component/RoomCategory'));
const RoomCategoryDetailPage =  React.lazy(() => import('component/RoomCategoryDetail'));
const GalleryPage = React.lazy(() => import('./container/Gallery'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const AboutPage = React.lazy(() => import('./container/About'));
const AmenitiesPage = React.lazy(()=> import('./container/Amenities'))
const PolicyPage = React.lazy(() => import('./container/Policy'));
const GroupPoliciesPage = React.lazy(() => import('./container/GroupPolicies'));
const InsidersGuidePage = React.lazy(() => import('./container/InsidersGuide'));
const PressPage = React.lazy(() => import('./container/Press'));
const GroupsPage = React.lazy(()=> import('./container/Groups'));
// const ContactPage = React.lazy(() => import('./container/Contact'));
// const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App({ cmsData }) {

  const { 'core-data': coreData } = cmsData;
  const topOfPage = useRef(null);
  return (
    <Suspense fallback={<Loader></Loader>}>
      <span ref={topOfPage}>
      <Navbar  CoreData={coreData} /></span>
      <ScrollToTopButton topRef={topOfPage}></ScrollToTopButton>
      <Switch>
        <Route path="/rooms/:slug/:slug1" component={() => <RoomCategoryDetailPage />} />
        <Route path="/rooms/:slug" component={() => <RoomCategoryPage />} />
        <Route path="/rooms" component={() => <RoomsPage />} />
        <Route exact path="/gallery" component={() => <GalleryPage />} />
        <Route exact path="/dining" component={() => <DiningPage />} />
        <Route exact path="/about" component={() => <AboutPage />} />
        {/* <Route exact path="/special" component={() => <SpecialPage />} /> */}
        <Route exact path="/policies" component={() => <PolicyPage />} />
        <Route exact path="/group-policies" component={() => <GroupPoliciesPage />} />
        <Route exact path="/insiders-guide" component={() => <InsidersGuidePage />} />
        <Route exact path="/amenities" component={() => <AmenitiesPage />} />
        <Route exact path="/press" component={() => <PressPage />} />
        {/* <Route exact path="/groups" component={() => <GroupsPage />} /> */}
        <Route path="/" component={() => <HomePage />} />

        {/* <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route> */}
      </Switch>
      <Footer CoreData={coreData} />
      <ImageViewer />
    </Suspense>
  );
}


export default CmsDataWrapper(App, ['core-data']);
