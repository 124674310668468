import { Img } from "component/FrameL";
import Modal from "component/Modal";
import React, { createContext, useCallback, useContext, useState } from "react";

export const ImageViewerContext = createContext();

function ImageViewerProvider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(undefined);

  const displayImage = useCallback((url) => {
    setIsOpen(true);
    setActiveImage(undefined);
    Promise.resolve().then(() => {
      setActiveImage(url);
    })
  });

  const closeImage = useCallback((i) => {
    setIsOpen(false);
  });

  const value = { isOpen, activeImage, displayImage, closeImage };

  return <ImageViewerContext.Provider value={value} {...props} />;
}

function useImageViewerContext() {
  return useContext(ImageViewerContext);
}

function ImageViewer() {
  const { isOpen, closeImage, activeImage } = useImageViewerContext();

  return <Modal isOpen={isOpen} onClose={closeImage} className="no-animate bg-black bg-opacity-80" closeIconClass="m-2">
    <div className="">
      <Img className="mx-auto max-h-screen max-w-full" src={activeImage} />
    </div>
  </Modal>
}

export { ImageViewer, ImageViewerProvider, useImageViewerContext };
